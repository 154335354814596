import { createUseStyles, multiply } from '@pushpay/styles';

import { useBranding } from '../../components/branding';
import { PlayButton } from '../../components/playButton';
import { Theme } from '../../components/theme';

const useStyles = createUseStyles((theme: Theme) => ({
	featuredContainer: {
		position: 'relative',
		top: 0,
		left: 0,
		right: 0,
		paddingBottom: '40%',
	},
	featuredWrapper: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	featuredPane: {
		position: 'absolute',
		backgroundColor: '#000',
		width: '100%',
		height: multiply('100vw', 9 / 16), // 16:9 ratio
		zIndex: 0,
		overflow: 'hidden',
	},
	featuredImage: {
		position: 'absolute',
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		width: '100%',
		opacity: 1,
		transition: 'opacity .4s cubic-bezier(.665,.235,.265,.8) 0s',
	},
	vignetteLeft: {
		backgroundImage: 'linear-gradient(77deg,rgba(0,0,0,.6) 0,rgba(0,0,0,0) 85%)',
		position: 'absolute',
		top: 0,
		left: 0,
		right: '26.09%',
		bottom: 0,
		opacity: 1,
		transition: 'opacity 0.5s',
		zIndex: 8,
	},
	vignetteBottom: {
		backgroundImage: `linear-gradient(to bottom,rgba(20,20,20,0) 0,rgba(20,20,20,.15) 15%,rgba(20,20,20,.35) 29%,rgba(20,20,20,.58) 44%,${theme['color-background-page']} 68%,${theme['color-background-page']} 100%)`,
		backgroundSize: '100% 100%',
		backgroundPosition: '0 top',
		backgroundRepeat: 'repeat-x',
		backgroundColor: 'transparent',
		width: '100%',
		height: '14.7vw',
		top: 'auto',
		bottom: '-1px',
		opacity: 1,
		zIndex: 8,
		position: 'absolute',
		left: 0,
		right: 0,
	},
	featuredInfo: {
		position: 'absolute',
		left: '4%',
		bottom: '35%',
		width: '36%',
		zIndex: 10,

		'@media screen and (min-width: 1500px)': {
			left: '60px',
		},
	},
	titleContainer: {
		// minHeight: '13.2vw',
		position: 'relative',
		marginBottom: '1.2vw',
	},
	title: {
		margin: 0,
		fontSize: '4vw',
	},
	synopsis: {
		margin: '0.5vw 0 0 0',
		color: theme['color-text-default'],
		fontWeight: '400',
		lineHeight: 'normal',
		width: '100%',
		fontSize: '1.2vw',
		textShadow: '2px 2px 4px rgba(0,0,0,.45)',
		borderLeft: `4px solid ${theme['brand-primary-color']}`,
		paddingLeft: '1vw',

		'@media screen and (max-width: 800px)': {
			display: 'none',
		},
		// marginTop: '.1vw',
	},
	buttonsContainer: {
		marginTop: '1.5vw',
		whiteSpace: 'nowrap',
		display: 'flex',
		lineHeight: '88%',
	},
}));

export const HeroSection = () => {
	const classes = useStyles(undefined);
	const { hero } = useBranding();
	const videoId = 'b9a468a4-fe5c-4ab6-bfea-cb7231acb12d';

	return (
		<section className={classes.featuredContainer} aria-label="Featured content">
			<div className={classes.featuredWrapper}>
				<div className={classes.featuredPane}>
					<img className={classes.featuredImage} src={hero.src} alt="" />
					<div className={classes.vignetteLeft} />
					<div className={classes.vignetteBottom} />
					<div className={classes.featuredInfo}>
						<div className={classes.titleContainer}>
							<h1 className={classes.title}>{hero.title}</h1>
						</div>
						<div className={classes.synopsis}>{hero.description}</div>
						<div className={classes.buttonsContainer}>
							<PlayButton videoId={videoId} />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
