import { Slider } from '../../components/slider';
import { VideoRow, VideoRowTransition } from '../../components/videoRow';
import { VideoTile } from '../../components/videoTile';
import { useContinueWatching } from '../../utils';

export const ContinueWatchingRow = () => {
	const { getContinueWatchingVideos } = useContinueWatching();
	const continueWatchingVideos = getContinueWatchingVideos();

	return (
		<VideoRowTransition in={!!continueWatchingVideos.length}>
			<VideoRow header="Continue Watching">
				<Slider
					items={continueWatchingVideos}
					renderItem={({ item, hidden }) => <VideoTile hidden={hidden} {...item} />}
				/>
			</VideoRow>
		</VideoRowTransition>
	);
};
