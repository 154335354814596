import { generatePath, Link } from 'react-router-dom';

import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { PlayIcon } from '../icons';

const useStyles = createUseStyles({
	link: {
		color: 'black',
		padding: '0.8rem 2.4rem 0.8rem 2rem',
		backgroundColor: '#FFF',
		textDecoration: 'none',
		display: 'inline-flex',
		alignItems: 'center',
		borderRadius: '4px',
		outlineColor: '#FFF',

		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.75)',
		},
	},
	icon: {
		width: '2.4rem',
		height: '2.4rem',
		marginRight: '1rem',
	},
	text: {
		fontSize: '1.6rem',
		lineHeight: 1.5,
		fontWeight: 700,
	},
});

export const PlayButton = ({ videoId }: ComponentProps<{ videoId: string }, typeof useStyles>) => {
	const classes = useStyles(undefined);

	return (
		<Link to={generatePath('watch/:videoId', { videoId })} className={classes.link}>
			<PlayIcon className={classes.icon} />
			<span className={classes.text}>Play</span>
		</Link>
	);
};
