import { createUseStyles, clsx } from '@pushpay/styles';

import { Icon, IconProps } from './Icon';

const useStyles = createUseStyles({
	root: {},
	animate: {
		animation: '$spin 1s linear infinite',
	},
	'@keyframes spin': {
		'100%': {
			transform: 'rotate(360deg)',
		},
	},
});

type LoadingIconProps = IconProps & { animate?: boolean };

export const LoadingIcon = (props: LoadingIconProps) => {
	const { animate = true, className: classNameProp, classes: classesProp, ...rest } = props;
	const classes = useStyles(classesProp);
	const className = clsx(classNameProp, classes.root, animate && classes.animate);

	return (
		<Icon viewBox="0 0 38 38" className={className} {...rest}>
			<g transform="translate(1 1)" strokeWidth={2} fill="none" fillRule="evenodd" stroke="currentColor">
				<circle strokeOpacity={0.5} cx={18} cy={18} r={18} />
				<path d="M36 18c0-9.94-8.06-18-18-18" />
			</g>
		</Icon>
	);
};
