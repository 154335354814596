import { useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { useBranding } from '../components/branding';
import { notEmpty } from './arrayHelpers';

type ContinueWatchingVideo = {
	lastUpdated: number;
	currentTime: number;
	duration: number;
};

type ContinueWatching = Record<string, ContinueWatchingVideo>;

const defaultContinueWatching: ContinueWatching = {};

export function useContinueWatching() {
	const { churchHandle } = useParams<'churchHandle'>();
	const [continueWatching, setContinueWatching] = useLocalStorage(
		`pushpaytv:${churchHandle}:currentlyWatching`,
		defaultContinueWatching
	);
	const { playlists } = useBranding();

	const getContinueWatchingVideos = useCallback(
		() =>
			Object.entries(continueWatching)
				.sort(([, videoA], [, videoB]) => videoB.lastUpdated - videoA.lastUpdated)
				.map(([videoId]) => playlists.flatMap(x => x.videos).find(video => video.videoId === videoId))
				.filter(notEmpty),
		[continueWatching, playlists]
	);

	const getContinueWatchingVideo = useCallback(
		(videoId: string | undefined): ContinueWatchingVideo | undefined =>
			videoId ? continueWatching[videoId] : undefined,
		[continueWatching]
	);

	const removeContinueWatchingVideo = useCallback(
		(videoId: string) => {
			setContinueWatching(current => {
				const { [videoId]: deleted, ...rest } = current;
				return { ...rest };
			});
		},
		[setContinueWatching]
	);

	const setContinueWatchingVideo = useCallback(
		({ videoId, currentTime, duration }: { videoId: string; currentTime: number; duration: number }) => {
			setContinueWatching(current => ({
				...current,
				[videoId]: { lastUpdated: Date.now(), currentTime, duration },
			}));
		},
		[setContinueWatching]
	);

	return {
		continueWatching,
		getContinueWatchingVideos,
		getContinueWatchingVideo,
		removeContinueWatchingVideo,
		setContinueWatchingVideo,
	};
}
