import { useCallback, useRef } from 'react';

export function useThrottle() {
	const throttled = useRef(false);

	return useCallback((callback: () => void, timeout: number) => {
		if (throttled.current) {
			return;
		}
		throttled.current = true;
		callback();

		window.setTimeout(() => {
			throttled.current = false;
		}, timeout);
	}, []);
}
