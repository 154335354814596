import { Branding } from '../branding';

export const brandColors = {
	timessquarechurch: '#E00035',
};

export const theme = {
	'typography-font-family': 'Roboto, Arial, sans-serif',
	'color-text-default': '#FFF',
	'color-background-page': '#141414',
	'breakpoints-slider-xsmall': 'screen and (max-width: 499px)',
	'breakpoints-slider-small': 'screen and (min-width: 500px) and (max-width: 799px)',
	'breakpoints-slider-medium': 'screen and (min-width: 800px) and (max-width: 1099px)',
	'breakpoints-slider-large': 'screen and (min-width: 1100px) and (max-width: 1399px)',
	'breakpoints-slider-xlarge': 'screen and (min-width: 1400px)',
};

export type Theme = typeof theme & Branding;
