import { PropsWithChildren } from 'react';

import { Transition } from 'react-transition-group';
import useMeasure from 'react-use-measure';

import { createUseStyles } from '@pushpay/styles';

const useStyles = createUseStyles({
	exiting: {
		height: 0,
		transition: 'height 250ms ease-in',
	},
});

export const VideoRowTransition = ({ children, in: inProp }: PropsWithChildren<{ in: boolean }>) => {
	const classes = useStyles(undefined);
	const [ref, { height: measuredHeight }] = useMeasure();
	const height = inProp && measuredHeight ? measuredHeight : undefined;

	return (
		<Transition in={inProp} timeout={250} unmountOnExit>
			{state => (
				<div ref={ref} className={classes[state as keyof typeof classes]} style={{ height }}>
					{children}
				</div>
			)}
		</Transition>
	);
};
