import { VideoInfo } from '../types';
import { shuffleArray } from '../utils';
import { demoPlaylistJson } from './DemoPlaylist';
import timesSquarePlaylistJson from './TimesSquarePlaylist.json';

function extractId(mainfestUrl: string) {
	return mainfestUrl.split('/').reverse()[1];
}

function transformPlaylistJson(json: typeof timesSquarePlaylistJson): VideoInfo[] {
	return json.map(({ title, description, thumbnails, cdnManifestUrls }) => ({
		videoId: extractId(cdnManifestUrls[0].dashUrl),
		title,
		description,
		thumbnail: thumbnails[0].representations[0].publicUrl,
	}));
}

const timesSquareChurchPlaylist = transformPlaylistJson(timesSquarePlaylistJson);

export const timesSquareChurchPlaylists = [
	{
		name: 'Evangelistic Messages',
		videos: shuffleArray(timesSquareChurchPlaylist),
	},
	{
		name: 'Counseling Series',
		videos: shuffleArray(timesSquareChurchPlaylist),
	},
	{
		name: 'Friday 7pm Series',
		videos: shuffleArray(timesSquareChurchPlaylist),
	},
	{
		name: 'Leadership Series',
		videos: shuffleArray(timesSquareChurchPlaylist),
	},
	{
		name: 'Sermon Series',
		videos: shuffleArray(timesSquareChurchPlaylist),
	},
];

const demoPlaylist = transformPlaylistJson(demoPlaylistJson);

export const demoPlaylists = [
	{
		name: 'Evangelistic Messages',
		videos: shuffleArray(demoPlaylist),
	},
	{
		name: 'Counseling Series',
		videos: shuffleArray(demoPlaylist),
	},
	{
		name: 'Friday 7pm Series',
		videos: shuffleArray(demoPlaylist),
	},
	{
		name: 'Leadership Series',
		videos: shuffleArray(demoPlaylist),
	},
	{
		name: 'Sermon Series',
		videos: shuffleArray(demoPlaylist),
	},
];
