import { paramCase } from 'param-case';
import { generatePath, Link } from 'react-router-dom';

import { createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { ArrowSingleIcon } from '../icons';
import { Theme } from '../theme';

const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		margin: '3vw 0',
		position: 'relative',
		outline: 0,
		transition: 'transform .54s cubic-bezier(.5,0,.1,1)',
		zIndex: 1,
	},
	header: {
		lineHeight: 1.3,
		margin: 0,
	},
	headerLink: {
		fontSize: '1.4vw',
		fontWeight: 700,
		color: '#E5E5E5',
		margin: '0 4% .5em 4%',
		textDecoration: 'none',
		minWidth: '6em',
		display: 'inline-flex',
		alignItems: 'flex-end',

		'&:hover, &:focus': {
			color: theme['color-text-default'],
		},

		'@media screen and (max-width: 950px)': {
			fontSize: '18px',
		},

		'@media screen and (min-width: 1500px)': {
			marginLeft: '60px',
		},
	},
	headerTitle: {
		lineHeight: '1.25vw',
		marginRight: '0.3vw',
	},
	seeAllContainer: {
		display: 'inline-flex',
		alignItems: 'flex-end',
	},
	seeAllLink: {
		fontSize: '0.9vw',
		color: theme['color-text-default'],
		lineHeight: 1,
		fontWeight: 700,
		display: 'inline-block',
		marginRight: '4px',
		maxWidth: 0,
		opacity: 0,
		transition: 'max-width 1s, opacity 1s, transform 750ms',
		whiteSpace: 'nowrap',

		'@media screen and (max-width: 950px)': {
			fontSize: '12px',
		},

		'$headerLink:hover &, $headerLink:focus &': {
			maxWidth: '200px',
			opacity: 1,
			transform: 'translate(0.6vw, 0)',
		},
	},
	seeAllIconContainer: {
		backgroundColor: theme['brand-primary-color'],
		color: theme['color-text-default'],
		fontSize: '0.9vw',
		transition: '750ms transform',
		borderRadius: '50%',
		display: 'flex',
		margin: '0 0 -0.1vw 0.3vw',
		width: '1.3vw',
		height: '1.3vw',
		alignItems: 'center',
		justifyContent: 'center',

		'@media screen and (max-width: 950px)': {
			fontSize: '12px',
			width: '18px',
			height: '18px',
			marginBottom: '-5px',
		},

		'$headerLink:hover &, $headerLink:focus &': {
			transform: 'translate(0.6vw, 0)',
		},
	},
}));

type VideoRowProps = ComponentProps<
	{
		header: string;
	},
	typeof useStyles
>;

export const VideoRow = ({ header, children }: VideoRowProps) => {
	const classes = useStyles(undefined);

	return (
		<div className={classes.container}>
			<h2 className={classes.header}>
				<Link
					className={classes.headerLink}
					to={generatePath('explore/:category', { category: paramCase(header) })}
				>
					<div className={classes.headerTitle}>{header}</div>
					<div className={classes.seeAllContainer}>
						<div className={classes.seeAllLink}>Explore all</div>
						<div className={classes.seeAllIconContainer}>
							<ArrowSingleIcon direction="right" />
						</div>
					</div>
				</Link>
			</h2>
			{children}
		</div>
	);
};
