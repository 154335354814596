import { SVGProps } from 'react';

import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

const useStyles = createUseStyles({
	root: {
		width: '1em',
		height: '1em',
	},
});

export type IconProps = ComponentProps<{ title?: string }, typeof useStyles, SVGProps<SVGSVGElement>>;

export const Icon = ({ className: classNameProp, classes: classesProp, title, children, ...rest }: IconProps) => {
	const classes = useStyles(classesProp);
	const className = clsx(classes.root, classNameProp);

	return (
		<svg className={className} {...rest}>
			{title && <title>{title}</title>}
			{children}
		</svg>
	);
};
