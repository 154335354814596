import { useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { useIsMounted } from 'usehooks-ts';

import { clsx } from '@pushpay/styles';

import { useBranding } from '../../components/branding';
import { ArrowIcon, LoadingIcon } from '../../components/icons';
import { VideoInfo } from '../../types';
import { useContinueWatching, useThrottle } from '../../utils';
import { loadVideoPlayer } from './loadVideoPlayer';
import { useStyles } from './styles';
import { useVideoPlayerReducer } from './videoPlayerReducer';

export const WatchPage = () => {
	const classes = useStyles(undefined);
	const [controlsVisible, setControlsVisible] = useState(false);
	const [youreWatchingVisible, setYoureWatchingVisible] = useState(false);
	const navigate = useNavigate();
	const videoPlayer = useRef<HTMLDivElement>(null);
	const [videoPlayerState, dispatch] = useVideoPlayerReducer();
	const throttle = useThrottle();
	const { playerState, playerKey } = videoPlayerState;
	const isMounted = useIsMounted();
	const { videoId } = useParams<'videoId'>();
	const { playlists } = useBranding();
	const { title, description } = playlists.flatMap(x => x.videos).find(x => x.videoId === videoId) as VideoInfo;
	const { setContinueWatchingVideo, removeContinueWatchingVideo, getContinueWatchingVideo } = useContinueWatching();

	useEffect(
		function observeVideoPlayer() {
			if (!videoPlayer.current) {
				return undefined;
			}

			const observer = new MutationObserver(() => {
				if (!isMounted()) {
					return;
				}
				const videoElement = document.getElementById('resi-video') as HTMLVideoElement | null;
				if (!videoElement) {
					return;
				}
				videoElement.oncanplay = () => {
					if (!isMounted()) {
						return;
					}
					dispatch({
						type: 'loaded',
						duration: videoElement.duration,
						videoContainer: document.getElementById('resi-videoContainer') as HTMLElement,
					});
					videoElement.onpause = () => dispatch({ type: 'pause' });
					videoElement.onplay = () => dispatch({ type: 'play' });
					videoElement.ontimeupdate = () => {
						const { currentTime, paused } = videoElement;
						const recordCurrentTime = () => {
							if (videoId) {
								setContinueWatchingVideo({
									videoId,
									currentTime: Math.round(currentTime),
									duration: Math.round(videoElement.duration),
								});
							}
						};
						if (paused) {
							recordCurrentTime();
						} else {
							throttle(recordCurrentTime, 2000);
						}
					};
					videoElement.onended = () => {
						if (videoId) {
							removeContinueWatchingVideo(videoId);
						}
					};
					videoElement.play();
				};
				observer.disconnect();
			});

			observer.observe(videoPlayer.current, { childList: true, subtree: true });
			return () => {
				observer.disconnect();
			};
		},
		[playerKey, dispatch, throttle, isMounted, videoId, setContinueWatchingVideo, removeContinueWatchingVideo]
	);

	useEffect(
		function loadAndRemovePlayer() {
			dispatch({ type: 'load' });
			const container = document.createElement('div');
			document.body.appendChild(container);
			loadVideoPlayer(container);

			return () => {
				document.body.removeChild(container);
			};
		},
		[dispatch]
	);

	useEffect(
		function toggleControls() {
			const opacity = controlsVisible ? '1' : '0';
			const visibility = controlsVisible ? 'visible' : 'hidden';
			document.querySelectorAll<HTMLElement>('.controls__container, #overlay-button')?.forEach(node => {
				// eslint-disable-next-line no-param-reassign
				node.style.opacity = opacity;
				// eslint-disable-next-line no-param-reassign
				node.style.visibility = visibility;
			});
		},
		[controlsVisible]
	);

	useEffect(
		function listenDuringPause() {
			let pauseTimeout: number;
			function startPauseTimeout() {
				pauseTimeout = window.setTimeout(() => {
					setControlsVisible(false);
					pauseTimeout = window.setTimeout(() => {
						setYoureWatchingVisible(true);
					}, 7000);
				}, 2500);
			}

			function cancelPause() {
				window.clearTimeout(pauseTimeout);
				setYoureWatchingVisible(false);
				setControlsVisible(true);
			}

			function cancelAndRestartPause() {
				cancelPause();
				startPauseTimeout();
			}

			if (playerState === 'paused') {
				startPauseTimeout();

				document.addEventListener('pointermove', cancelAndRestartPause);
				document.addEventListener('pointerdown', cancelAndRestartPause);

				return () => {
					cancelPause();
					document.removeEventListener('pointermove', cancelAndRestartPause);
					document.removeEventListener('pointerdown', cancelAndRestartPause);
				};
			}
			return undefined;
		},
		[playerState]
	);

	useEffect(
		function listenDuringPlay() {
			let playTimeout: number;
			function showControls() {
				window.clearTimeout(playTimeout);
				setControlsVisible(true);
				playTimeout = window.setTimeout(() => {
					setControlsVisible(false);
				}, 3000);
			}

			if (playerState === 'playing') {
				document.addEventListener('pointermove', showControls);
				document.addEventListener('pointerdown', showControls);

				return () => {
					window.clearTimeout(playTimeout);
					document.removeEventListener('pointermove', showControls);
					document.removeEventListener('pointerdown', showControls);
				};
			}
			return undefined;
		},
		[playerState]
	);

	return (
		<div className={classes.watchVideo}>
			<div
				key={playerKey}
				ref={videoPlayer}
				id="resi-video-player"
				data-embed-id="MDE0NGNjM2UtN2Q1Ni00Y2M5LWJmOTMtNjMyZjE4YjMxYTZjOmJlOWI3M2Y0LWI1MDctMTFlYy1iYjViLWIzMzdkNTViY2JmZg=="
				data-type="library"
				data-autoplay="false"
				data-start-pos={getContinueWatchingVideo(videoId)?.currentTime}
				className={clsx(classes.videoContainer, playerState === 'loading' && classes.videoContainerLoading)}
			/>
			<Transition in={playerState === 'loading'} timeout={{ exit: 500 }} unmountOnExit>
				{state => (
					<div className={clsx(classes.overlay, state === 'exiting' && classes.overlayExiting)} aria-hidden>
						<div className={classes.gradient} />
						<div className={classes.loadingTitleContainer}>
							<h3 className={classes.loadingTitle}>{title}</h3>
						</div>
					</div>
				)}
			</Transition>
			{playerState === 'loading' && (
				<div className={classes.loadingSpinner}>
					<LoadingIcon />
				</div>
			)}
			{(playerState === 'paused' || playerState === 'playing') &&
				createPortal(
					<>
						<Transition in={controlsVisible} timeout={1500}>
							{state => (
								<div
									className={clsx(
										classes.backButtonContainer,
										(state === 'entering' || state === 'entered') &&
											classes.backButtonContainerEntering
									)}
								>
									<button
										aria-label="Back to browse"
										className={classes.backButton}
										type="button"
										onClick={() => navigate(-1)}
									>
										<ArrowIcon />
									</button>
								</div>
							)}
						</Transition>
						{youreWatchingVisible && (
							<div
								className={classes.overlay}
								aria-hidden
								onClick={function clickThroughOverlay({ currentTarget, clientX, clientY }) {
									// eslint-disable-next-line no-param-reassign
									currentTarget.style.display = 'none';
									(document.elementFromPoint(clientX, clientY) as HTMLElement)?.click();
									setYoureWatchingVisible(false);
								}}
							>
								<div className={classes.overlayTextContainer}>
									<span className={classes.youreWatching}>You&apos;re watching</span>
									<h2 className={classes.title}>{title}</h2>
									<h3 className={classes.duration}>{videoPlayerState.duration}</h3>
									<p className={classes.synopsis}>{description}</p>
									<span className={classes.paused}>Paused</span>
								</div>
							</div>
						)}
					</>,
					videoPlayerState.videoContainer
				)}
		</div>
	);
};
