import { useCallback, useRef, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { useBranding } from '../branding';
import { SearchBox } from '../searchBox';
import { Theme } from '../theme';

const heightPx = '42px';
const heightWidePx = '68px';

const useStyles = createUseStyles((theme: Theme) => ({
	container: {},
	containerExiting: {
		visibility: 'hidden',
	},
	header: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		padding: '10px 4%',
		minHeight: heightPx,
		backgroundColor: 'transparent',
		transition: 'background-color 0.4s ease',
		backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,.7) 10%,rgba(0,0,0,0))',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		zIndex: 10,

		'@media screen and (min-width: 950px)': {
			minHeight: heightWidePx,
		},

		'@media screen and (min-width: 1500px)': {
			padding: '10px 60px',
		},
	},
	sticky: {
		transition: 'background-color 0.2s ease',
		backgroundColor: theme['color-background-page'],
		paddingTop: '8px',
		paddingBottom: '8px',
	},
	link: {
		lineHeight: 0,
	},
	logo: {
		maxHeight: '76px',
		transition: 'max-height 0.3s ease',

		'@media screen and (max-width: 950px)': {
			maxHeight: '44px',
			transition: 'max-height 0.1s ease',
		},
	},
	logoSticky: {
		maxHeight: '52px',

		'@media screen and (max-width: 950px)': {
			maxHeight: '40px',
		},
	},
}));

type HeaderProps = ComponentProps<{ exiting?: boolean }, typeof useStyles>;

export const Header = ({ classes: classesProp, className: classNameProp, exiting }: HeaderProps) => {
	const classes = useStyles(classesProp);
	const [isSticky, setIsSticky] = useState(false);

	const observer = useRef(
		new IntersectionObserver(
			function onIntersect([element]) {
				setIsSticky(!element.isIntersecting);
			},
			{ threshold: 1 }
		)
	);

	const wrapperRef = useCallback((node: HTMLDivElement | null) => {
		if (node) {
			observer.current.observe(node);
		} else {
			observer.current.disconnect();
		}
	}, []);

	const className = clsx(classes.header, classNameProp, isSticky && classes.sticky);
	const { churchHandle } = useParams<'churchHandle'>();
	const { logo, name } = useBranding();

	return (
		<div className={clsx(classes.container, exiting && classes.containerExiting)} ref={wrapperRef}>
			<header className={className}>
				<Link className={classes.link} to={`/${churchHandle}`}>
					<img src={logo} className={clsx(classes.logo, isSticky && classes.logoSticky)} alt={name} />
				</Link>
				<SearchBox />
			</header>
		</div>
	);
};
