import { useLayoutEffect } from 'react';

import { Theme, useTheme } from '../components/theme';
import { useForceUpdate } from './useforceUpdate';

type TileBreakpoint = Extract<
	keyof Theme,
	| 'breakpoints-slider-xsmall'
	| 'breakpoints-slider-small'
	| 'breakpoints-slider-medium'
	| 'breakpoints-slider-large'
	| 'breakpoints-slider-xlarge'
>;

const listenersCache: { [breakpoint in TileBreakpoint]?: MediaQueryList } = {};

function useTileBreakpoint(breakpoint: TileBreakpoint) {
	const theme = useTheme();
	const forceUpdate = useForceUpdate();

	function getCachedListener() {
		if (!listenersCache[breakpoint]) {
			listenersCache[breakpoint] = window.matchMedia(theme[breakpoint]);
		}
		return listenersCache[breakpoint] as MediaQueryList;
	}

	const listener = getCachedListener();

	useLayoutEffect(
		function forceUpdateOnBreakpointChange() {
			listener.addEventListener('change', forceUpdate);
			return () => {
				listener.removeEventListener('change', forceUpdate);
			};
		},
		[forceUpdate, listener]
	);

	return listener?.matches ?? false;
}

export function useTileBreakpoints() {
	const xsmall = useTileBreakpoint('breakpoints-slider-xsmall');
	const small = useTileBreakpoint('breakpoints-slider-small');
	const medium = useTileBreakpoint('breakpoints-slider-medium');
	const large = useTileBreakpoint('breakpoints-slider-large');
	const xlarge = useTileBreakpoint('breakpoints-slider-xlarge');

	return {
		xsmall,
		small,
		medium,
		large,
		xlarge,
	};
}

export function useTileColumnCount() {
	const { xsmall, small, medium, large } = useTileBreakpoints();
	if (xsmall) {
		return 2;
	}
	if (small) {
		return 3;
	}
	if (medium) {
		return 4;
	}
	if (large) {
		return 5;
	}
	return 6;
}
