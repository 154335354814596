import { useState } from 'react';

import useResizeObserver from '@react-hook/resize-observer';

import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { Footer } from '../footer';
import { Header } from '../header';

const useStyles = createUseStyles({
	'@global': {
		'html, body': {
			textSizeAdjust: '100%',
			WebkitTextSizeAdjust: '100%',
			MozTextSizeAdjust: '100%',
			fontSize: '10px',

			'@media screen and (min-width: 0) and (max-width: 480px)': {
				fontSize: '1.5vw',
			},

			'@media screen and (min-width: 481px) and (max-width: 840px) and (max-aspect-ratio: 4 / 3)': {
				fontSize: '1vw',
			},

			'@media screen and (min-width: 481px) and (max-width: 840px) and (orientation: landscape)': {
				fontSize: '1.2vw',
			},

			'@media screen and (min-width: 1281px) and (max-width: 1600px) and (orientation: landscape)': {
				fontSize: '0.75vw',
			},

			'@media screen and (min-width: 1601px) and (max-width: 1920px) and (orientation: landscape)': {
				fontSize: '0.75vw',
			},

			'@media screen and (min-width: 1921px) and (orientation: landscape)': {
				fontSize: '14px',
			},
		},
		body: {
			overflowX: 'hidden',
		},
	},
	container: {
		zIndex: 0,
		overflow: 'hidden',
		position: 'relative',
	},
	containerExiting: {
		transform: `scale(1.04)`,
		opacity: 0,
		transition: 'opacity 750ms ease-in, transform 750ms ease-in',
	},
	main: {
		zIndex: 0,
		position: 'relative',
		paddingBottom: '20px',
		minHeight: '1000px',
	},
});

type LayoutProps = ComponentProps<{ exiting?: boolean; hide?: boolean }, typeof useStyles>;

export const Layout = ({
	classes: classesProp,
	className: classNameProp,
	exiting,
	hide,
	children,
	...rest
}: LayoutProps) => {
	const getTransformOriginY = () => window.pageYOffset + Math.round(window.innerHeight / 2);
	const [transformOriginY, setTransformOriginY] = useState(getTransformOriginY);

	const classes = useStyles(classesProp);
	const className = clsx(classes.container, classNameProp, exiting && classes.containerExiting);

	useResizeObserver(document.body, () => setTransformOriginY(getTransformOriginY()));

	if (hide) {
		return <>{children}</>;
	}

	return (
		<div
			id="layout-container"
			className={className}
			style={{ transformOrigin: `50% ${transformOriginY}` }}
			{...rest}
		>
			<Header exiting={exiting} />
			<main className={classes.main}>{children}</main>
			<Footer />
		</div>
	);
};
