import { useBranding } from '../../components/branding';
import { Slider } from '../../components/slider';
import { VideoRow } from '../../components/videoRow';
import { VideoTile } from '../../components/videoTile';
import { ContinueWatchingRow } from './ContinueWatchingRow';
import { HeroSection } from './HeroSection';

export const HomePage = () => (
	<div>
		<HeroSection />
		<section aria-label="Videos">
			<ContinueWatchingRow />
			{useBranding().playlists.map(({ name, videos }) => (
				<VideoRow key={name} header={name}>
					<Slider items={videos} renderItem={({ item, hidden }) => <VideoTile hidden={hidden} {...item} />} />
				</VideoRow>
			))}
		</section>
	</div>
);
