export function getWindowRect() {
	const { innerWidth, innerHeight } = window;

	return {
		top: 0,
		bottom: innerHeight,
		left: 0,
		right: innerWidth,
		width: innerWidth,
		height: innerHeight,
	};
}
