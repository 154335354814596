import { createRoot } from 'react-dom/client';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

if ('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual';
}

const reactContainer = document.getElementById('root');
if (reactContainer) {
	createRoot(reactContainer).render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
