import { Icon, IconProps } from './Icon';

export const PlayIcon = (props: IconProps) => (
	<Icon viewBox="0 0 24 24" {...props}>
		<path
			d="M4 2.69127C4 1.93067 4.81547 1.44851 5.48192 1.81506L22.4069 11.1238C23.0977 11.5037 23.0977 12.4963 22.4069 12.8762L5.48192 22.1849C4.81546 22.5515 4 22.0693 4 21.3087V2.69127Z"
			fill="currentColor"
		/>
	</Icon>
);
