import { clsx, createUseStyles } from '@pushpay/styles';
import { ComponentProps, memo } from '@pushpay/types';

const useStyles = createUseStyles({
	container: {
		position: 'relative',
		display: 'inline-block',
		verticalAlign: 'top',
		whiteSpace: 'normal',
		zIndex: 1,
		margin: '0 0.3vw',

		'&:first-child': {
			marginLeft: 0,
		},
	},
});

type SliderItemProps = ComponentProps<{ exiting?: boolean }, typeof useStyles>;

export const SliderItem = memo(
	({ exiting, classes: classesProp, className: classNameProp, children, ...rest }: SliderItemProps) => {
		const classes = useStyles(classesProp);
		const className = clsx(classes.container, classNameProp);

		return (
			<div className={className} {...rest}>
				{children}
			</div>
		);
	}
);
