import { createUseStyles, clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

type AnimatedTickIconProps = {
	/**
	 * How long the tick should take to animate in milliseconds
	 *
	 * @default 300
	 */
	animationDuration?: number;
	/**
	 * How long before the tick stats animating in milliseconds\
	 *
	 * @default 0
	 */
	animationDelay?: number;
};

type StyleParams = {
	animationDuration: number;
	animationDelay: number;
};

const useStyles = createUseStyles({
	root: ({ animationDuration, animationDelay }: StyleParams) => ({
		position: 'relative',
		height: '1.25em',
		width: '1.25em',
		backgroundColor: 'transparent',
		transform: 'rotateZ(-45deg)',
		'&:before, &:after': {
			content: '""',
			height: '4px',
			width: '4px',
			position: 'absolute',
			left: 0,
			backgroundColor: 'currentColor',
			transform: 'translateY(-10px)',
		},

		'&:before': {
			height: 0,
			top: '50%',
			animation: `${animationDuration / 2}ms ease-out ${animationDelay}ms forwards $tickHorizontal`,
		},

		'&:after': {
			width: 0,
			bottom: 0,
			animation: `${animationDuration / 2}ms ease-out ${
				animationDelay + animationDuration / 2
			}ms forwards $tickVertical`,
		},
	}),
	'@keyframes tickHorizontal': {
		'0%': {
			height: 0,
		},
		'100%': {
			height: '40%',
		},
	},
	'@keyframes tickVertical': {
		'0%': {
			width: 0,
		},
		'100%': {
			width: '80%',
		},
	},
});

type AnimatedTickIconComponentProps = ComponentProps<AnimatedTickIconProps, typeof useStyles>;

export const AnimatedTickIcon = ({
	animationDuration = 300,
	animationDelay = 0,
	classes: classesProp,
	className: classNameProp,
}: AnimatedTickIconComponentProps) => {
	const classes = useStyles(classesProp, { animationDuration, animationDelay });
	const className = clsx(classNameProp, classes.root);
	return <div className={className} />;
};
