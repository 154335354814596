import { SVGProps } from 'react';

import { createUseStyles, clsx } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { Icon } from './Icon';

const useStyles = createUseStyles({
	root: {
		height: '1em',
		width: '3.75em',
	},
});

type PushPayLogoIconComponentProps = ComponentProps<{ title?: string }, typeof useStyles, SVGProps<SVGSVGElement>>;

export const PushpayLogoIcon = ({
	className: classNameProp,
	classes: classesProp,
	...rest
}: PushPayLogoIconComponentProps) => {
	const classes = useStyles(classesProp);
	const className = clsx(classNameProp, classes.root);
	return (
		<Icon viewBox="0 0 146 40" className={className} {...rest}>
			<g fill="currentColor" fillRule="nonzero">
				<path d="M24.436 10.683h-4.813L16.035 29.28h3.18l1.077-5.79h3.588c3.996 0 7.337-2.447 7.337-6.856 0-3.218-2.079-5.95-6.78-5.95zm-.804 9.963h-2.784l1.386-7.106h2.35c1.894 0 3.465 1.007 3.465 3.193-.012 2.584-2.016 3.913-4.417 3.913zM16.246 10.683L12.657 29.28h1.51l3.563-18.597zM12.855 10.683L9.28 29.28h1.497l3.576-18.597z" />
				<path d="M19.87.087C8.92.087.05 8.994.05 19.987c0 10.994 8.87 19.901 19.82 19.901 10.95 0 19.822-8.907 19.822-19.9C39.692 8.994 30.821.087 19.871.087zm0 38.422c-10.182 0-18.447-8.285-18.447-18.521S9.688 1.466 19.87 1.466s18.448 8.286 18.448 18.522c0 10.236-8.265 18.521-18.448 18.521z" />
				<g>
					<path d="M72.728 23.118c0 2.236-1.188 3.64-3.02 3.64-1.769 0-2.808-1.218-2.808-3.242V16.26h-3.068v7.838c0 3.38 1.88 5.503 4.949 5.503 1.806 0 3.155-.82 3.947-2.05v1.728h3.068V16.26h-3.068v6.858zM80.498 19.876c0-.795.817-1.255 2.066-1.255 1.584 0 3.093.634 4.318 1.677v-3.217c-1.187-.671-2.697-1.143-4.206-1.143-3.044 0-5.16 1.652-5.16 4.199 0 4.807 6.966 2.894 6.966 5.316 0 1.031-.928 1.441-2.165 1.441-1.67 0-3.39-.77-4.69-1.863v3.317c1.275.795 2.883 1.254 4.814 1.254 2.722 0 5.085-1.515 5.085-4.335-.013-4.807-7.028-3.13-7.028-5.391zM95.989 15.938c-1.831 0-3.18.82-3.972 2.05v-7.305H88.95v18.609h3.068v-6.857c0-2.236 1.213-3.64 3.044-3.64 1.856 0 2.883 1.217 2.883 3.242v7.255h3.068v-7.839c.013-3.39-1.868-5.515-5.023-5.515zM110.341 15.938c-1.806 0-3.44.845-4.392 2.174v-1.864h-3.044v18.56h3.044V27.49c.953 1.329 2.598 2.1 4.392 2.1 3.576 0 6.323-3.007 6.323-6.833.012-3.864-2.747-6.82-6.323-6.82zm-.63 10.882c-2.203 0-3.898-1.777-3.898-4.037 0-2.261 1.695-4.038 3.897-4.038 2.203 0 3.885 1.777 3.885 4.038 0 2.248-1.695 4.037-3.885 4.037zM128.53 18.037c-.953-1.329-2.599-2.1-4.393-2.1-3.576 0-6.322 3.007-6.322 6.833 0 3.876 2.759 6.832 6.322 6.832 1.807 0 3.44-.844 4.392-2.173v1.863h3.044V16.26h-3.044v1.776zm-3.762 8.783c-2.202 0-3.897-1.777-3.897-4.037 0-2.261 1.695-4.038 3.897-4.038 2.203 0 3.885 1.777 3.885 4.038 0 2.248-1.695 4.037-3.885 4.037zM142.795 16.26l-3.65 9.38-3.55-9.38h-3.255l5.185 12.846-.557 1.354c-.606 1.465-1.3 1.987-2.376 1.987-.507 0-.977-.1-1.348-.26v2.633a4.33 4.33 0 0 0 1.806.373c2.202 0 3.625-1.255 4.9-4.336l6.013-14.596h-3.168zM55.517 10.683h-5.37v18.609h3.18v-5.789h1.831c4.083 0 7.412-2.236 7.412-6.435 0-3.813-2.735-6.385-7.053-6.385zm-.124 9.963h-2.066V13.54h2.066c2.648 0 3.923 1.38 3.923 3.59 0 2-1.35 3.516-3.923 3.516zM145.122 27.714c0-.323-.248-.521-.62-.521h-.717v1.627h.31v-.56h.334l.396.56h.37l-.432-.609c.198-.074.359-.236.359-.497zm-.644.274h-.396v-.51h.396c.198 0 .322.087.322.261 0 .15-.112.249-.322.249z" />
					<path d="M144.404 26.497a1.55 1.55 0 0 0-1.547 1.553c0 .844.68 1.552 1.547 1.552a1.55 1.55 0 0 0 1.547-1.552c0-.857-.681-1.553-1.547-1.553zm-.012 2.956a1.37 1.37 0 0 1-1.386-1.39c0-.771.594-1.405 1.386-1.405a1.37 1.37 0 0 1 1.385 1.392c0 .77-.594 1.403-1.385 1.403z" />
				</g>
			</g>
		</Icon>
	);
};
