import connectingWithCommunityThumbnail from '../images/demo/ConnectingWithCommunity.jpg';
import fromEngagementToInvolvementThumbnail from '../images/demo/FromEngagementToInvolvement.jpg';
import keepingTheDoorsOpenThumbnail from '../images/demo/KeepingTheDoorsOpen.jpg';
import ledWallsABeginnersGuideThumbnail from '../images/demo/LedWallsABeginnersGuide.jpg';
import pushpayAndResiThumbnail from '../images/demo/PushpayAndResi.jpg';
import reachingTheUnreachableThumbnail from '../images/demo/ReachingTheUnreachable.jpg';
import streamingOverCellularHotspotsThumbnail from '../images/demo/StreamingOverCellularHotspots.jpg';
import streamingThroughStarlinkThumbnail from '../images/demo/StreamingThroughStarlink.jpg';
import theBestCamerasForLivestreamingThumbnail from '../images/demo/TheBestCamerasForLivestreaming.jpg';
import unboundGenerosityThumbnail from '../images/demo/UnboundGenerosity.jpg';
import whatIsSimLiveThumbnail from '../images/demo/WhatIsSimLive.jpg';
import worshipLeaderPropsThumbnail from '../images/demo/WorshipLeaderProbs.jpg';

export const demoPlaylistJson = [
	{
		title: 'Unbound Generosity',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 3500,
		thumbnails: [
			{
				name: 'Unbound Generosity',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: unboundGenerosityThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/e7fbdb7a-ccdb-477c-a692-8411ce13ac89/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'Connecting with Community',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 3400,
		thumbnails: [
			{
				name: 'Connecting with Community',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: connectingWithCommunityThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/8a31d546-5a7a-4e53-bbdd-624fbc588be2/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'What is Sim Live?',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 2200,
		thumbnails: [
			{
				name: 'What is Sim Live?',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: whatIsSimLiveThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/8350b1cf-0e9f-4060-a5c6-d788d2514464/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'Streaming Over Cellular Hotspots',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 3700,
		thumbnails: [
			{
				name: 'Streaming Over Cellular Hotspots',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: streamingOverCellularHotspotsThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/165d67a5-9a8e-41f2-93ea-08041870ae61/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'From Engagement to Involvement',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 3000,
		thumbnails: [
			{
				name: 'From Engagement to Involvement',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: fromEngagementToInvolvementThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/4cd979aa-536b-4130-992e-49b7b4f6efee/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'Led Walls: A Beginners Guide',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 3900,
		thumbnails: [
			{
				name: 'Led Walls: A Beginners Guide',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: ledWallsABeginnersGuideThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/fe9d1681-ff04-4a74-8104-948dab3fa7c9/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'The Best Cameras for Livestreaming',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 4000,
		thumbnails: [
			{
				name: 'The Best Cameras for Livestreaming',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: theBestCamerasForLivestreamingThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/1a291f2f-6895-4272-92c1-617ca5a50b25/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'Pushpay and Resi',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 1500,
		thumbnails: [
			{
				name: 'Pushpay and Resi',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: pushpayAndResiThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/a9a2faab-f9ba-4d43-9481-0147d18a5572/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'Reaching the Unreachable',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 3450,
		thumbnails: [
			{
				name: 'Reaching the Unreachable',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: reachingTheUnreachableThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/f9a0dc5f-e177-4483-8f53-244533629e99/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'Keeping the Doors Open',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 2800,
		thumbnails: [
			{
				name: 'Keeping the Doors Open',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: keepingTheDoorsOpenThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/d78b8174-b588-41c6-acee-81c61dc29dcc/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'Streaming Through Starlink',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 2900,
		thumbnails: [
			{
				name: 'Streaming Through Starlink',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: streamingThroughStarlinkThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/aeffdc37-3176-41c6-b98a-17a40f6f3063/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
	{
		title: 'Worship Leader Probs',
		description:
			"Description goes here. Resi's reliable live streaming platform allows you to stream confidently, knowing your stream will never fall flat due to unstable network connections.",
		duration: 3300,
		thumbnails: [
			{
				name: 'Worship Leader Probs',
				representations: [
					{
						quality: 'default',
						width: 0,
						height: 0,
						publicUrl: worshipLeaderPropsThumbnail,
					},
				],
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
			},
		],
		cdnManifestUrls: [
			{
				dashUrl:
					'https://lib.resi.media/85ab0607-8ef6-41d5-9024-d50200574303/9a747160-bdae-4123-b107-463e7e7f6d53/Manifest-9146bdbb.mpd',
				hlsUrl: '',
			},
		],
	},
];
