import { createUseStyles } from '@pushpay/styles';

import { Theme } from '../theme';

const useFontStyles = createUseStyles((theme: Theme) => ({
	// '@import': 'url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap)',
	'@import': 'url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap)',
	'@global': {
		body: {
			fontFamily: theme['typography-font-family'],
			lineHeight: 1.2,
			color: theme['color-text-default'],
			WebkitFontSmoothing: 'antialiased',
			MozOsxFontSmoothing: 'grayscale',
		},
	},
}));

const useStyles = createUseStyles((theme: Theme) => ({
	'@global': {
		'html, body': {
			margin: 0,
			padding: 0,
			backgroundColor: theme['color-background-page'],
			userSelect: 'none',
		},
		body: {},

		'*, *:before, *:after': {
			boxSizing: 'border-box',
		},

		':focus-visible': {
			outlineOffset: '1px',
			outlineWidth: 'medium',
			outlineStyle: 'solid',
			outlineColor: 'currentColor',
		},

		// iOS "clickable elements" fix for role="button"
		//
		// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
		// for traditionally non-focusable elements with role="button"
		// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
		'[role="button"]': {
			cursor: 'pointer',
		},

		'dl, dd, ul': {
			margin: 0,
		},
	},
}));

export const CssReset = () => {
	useFontStyles(undefined);
	useStyles(undefined);
	return null;
};
