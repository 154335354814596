// This is a copy of https://control.resi.io/webplayer/loader.min.js but allows the loading function to be called and style and script tags
// be attached to an element of our choosing. This allows us to unmount the tags
/* eslint-disable */
function n(t: any, e = window.location.href) {
	let r;
	const a = new RegExp(`[?&]${t}=([^&#]*)`, 'i').exec(e);
	return a != null && a.length && (r = a[1]) != null ? r : null;
}

function p(t: any) {
	return t != null;
}

const l = () => document.body.getAttribute('data-resi-body') === 'true';
const d = () => {
	let t;
	return (
		n('version') === '1' ||
		((t = document.getElementById('resi-video-player')) == null ? void 0 : t.getAttribute('data-version')) === '1'
	);
};
const u = () => {
	let t;
	return (
		n('type') === 'playlist' ||
		((t = document.getElementById('resi-video-player')) == null ? void 0 : t.getAttribute('data-type')) ===
			'playlist'
	);
};
const c = () => {
	const t = document.body;
	const e = document.getElementById('player-wrapper');
	t &&
		e &&
		((t.style.backgroundColor = 'transparent'),
		(t.style.overflow = 'hidden'),
		(t.style.position = 'absolute'),
		(t.style.top = '0'),
		(t.style.bottom = '0'),
		(t.style.left = '0'),
		(t.style.right = '0'),
		(t.style.margin = '0'),
		(t.style.padding = '0'),
		(t.style.display = 'flex'),
		(t.style.justifyContent = 'center'),
		(t.style.alignItems = 'center'),
		(e.style.backgroundColor = 'transparent'),
		(e.style.overflow = 'hidden'),
		(e.style.textEmphasisPosition = 'relative'),
		(e.style.width = '100%'),
		(e.style.maxWidth = 'calc(177.778vh)'),
		(e.style.height = 'calc(56.25vw)'),
		(e.style.maxHeight = '100%'),
		(e.style.margin = '0'),
		(e.style.padding = '0'));
};
const i = 'https://control.resi.io/webplayer/';
const m = 'webplayer-v3.703.bundle.js';
const y = 'css/webplayer-v3.703.min.css';
const b = 'loader-legacy.min.js';
const f = 'standalone-legacy.min.js';

const s = (t: any, container: HTMLElement) => {
	const e = document.createElement('script');
	e.setAttribute('src', i + t);
	container.appendChild(e);
};

function h(container: HTMLElement) {
	if (i.includes('LOADER_BASE_URL')) {
		console.warn('Failed to interpolate base path variable');
		return;
	}
	if (d() && !u()) {
		l() && (c(), s(f, container)), s(b, container);
		return;
	}
	const t = document.createElement('link');
	t.type = 'text/css';
	t.rel = 'stylesheet';
	t.href = i + y;
	container.appendChild(t);

	s(m, container);
}
const g = () => {
	const t = n('manifest');
	const e = n('encoded-manifest');
	if (!t && !e) return !0;
	const o = t && !t.includes('http://');
	const a = e && !e.includes('http://');
	return o || a;
};

function E() {
	g() &&
		window.location.protocol !== 'https:' &&
		location.hostname !== 'localhost' &&
		(location.href = location.href.replace('http://', 'https://'));
	const e = document.getElementById('resi-video-player');
	if (!e) {
		console.error('unable to find player wrapper');
		return;
	}
	c();
	const o = [
		{
			param: 'autoplay',
			attribute: 'data-autoplay',
		},
		{
			param: 'background',
			attribute: 'data-background',
		},
		{
			param: 'bitrateLimit',
			attribute: 'data-bitrate-limit',
		},
		{
			param: 'callToActionButton',
			attribute: 'data-call-to-action-button',
		},
		{
			param: 'chunkedTransfer',
			attribute: 'data-chunked-transfer',
		},
		{
			param: 'controls',
			attribute: 'data-controls',
		},
		{
			param: 'debug',
			attribute: 'data-debug',
		},
		{
			param: 'encoded_manifest',
			attribute: 'data-encoded-manifest',
		},
		{
			param: 'env',
			attribute: 'data-env',
		},
		{
			param: 'id',
			attribute: 'data-embed-id',
		},
		{
			param: 'iframeId',
			attribute: 'data-iframe-id',
		},
		{
			param: 'loop',
			attribute: 'data-loop',
		},
		{
			param: 'manifest',
			attribute: 'data-manifest',
		},
		{
			param: 'mute',
			attribute: 'data-mute',
		},
		{
			param: 'scrubBar',
			attribute: 'data-scrub-bar',
		},
		{
			param: 'startPos',
			attribute: 'data-start-pos',
		},
		{
			param: 'studio',
			attribute: 'data-studio',
		},
		{
			param: 'type',
			attribute: 'data-type',
		},
	];
	for (const a of o) {
		const r = n(a.param);
		!p(r) || e.setAttribute(a.attribute, r as string);
	}
}

export function loadVideoPlayer(container: HTMLElement) {
	h(container);
	l() && !d() && E();
}
