import { useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { generatePath, Link, useParams } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import { clsx, createUseStyles, CSSProperties } from '@pushpay/styles';
import { ComponentProps, memo } from '@pushpay/types';

import { getWindowRect, useContinueWatching } from '../../utils';
import { ProgressBar } from '../progressBar';
import { Theme } from '../theme';
import { VideoPreviewSmall } from '../videoPreview';

const useStyles = createUseStyles((theme: Theme) => ({
	container: {},
	link: {
		display: 'block',
		textDecoration: 'none',
		color: theme['color-text-default'],
		borderRadius: '0.2vw',
	},
	wrapper: {
		borderRadius: '0.2vw',
		width: '100%',
		height: 0,
		position: 'relative',
		overflow: 'hidden',
		padding: '28.125% 0',
	},
	thumbnail: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		width: '100%',
	},
	fallbackTextContainer: {
		position: 'absolute',
		zIndex: -1,
		bottom: 0,
		left: 0,
		right: 0,
		height: '100%',
		borderRadius: '4px',
	},
	fallbackText: {
		textAlign: 'center',
		position: 'absolute',
		bottom: 0,
		left: '8%',
		right: '8%',
		padding: '0 0 10%',
		fontSize: '1.5em',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	progressBarBottom: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	title: {
		marginTop: '12px',
		fontSize: '16px',
		lineHeight: 1.5,
		lineClamp: 2,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		boxOrient: 'vertical',
	},
	overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 2,
	},
}));

export type VideoTileProps = ComponentProps<
	{
		videoId: string;
		title: string;
		description: string;
		thumbnail: string;
		hidden?: boolean;
		continueWatching?: { currentTime: number; duration: number };
	},
	typeof useStyles
>;

export const VideoTile = memo(
	({
		classes: classesProp,
		className: classNameProp,
		videoId,
		title,
		description,
		thumbnail,
		hidden,
		...rest
	}: VideoTileProps) => {
		const { getContinueWatchingVideo } = useContinueWatching();
		const continueWatching = getContinueWatchingVideo(videoId);
		const { churchHandle } = useParams<'churchHandle'>();
		const watchRoute = generatePath('/:churchHandle/watch/:videoId', { churchHandle, videoId });
		const classes = useStyles(classesProp);
		const className = clsx(classes.link, continueWatching && classes.progressBarBottom, classNameProp);

		const containerRef = useRef<HTMLDivElement>(null);
		const videoPreviewRef = useRef<HTMLDivElement>(null);
		const [showPreview, setShowPreview] = useState(false);
		const queueShowPreview = useRef<number>();

		useEffect(
			function keyboardHandler() {
				function onEscape({ key }: KeyboardEvent) {
					if (key === 'Escape') {
						setShowPreview(false);
					}
				}
				if (showPreview) {
					document.body.addEventListener('keydown', onEscape);
					return () => {
						document.body.removeEventListener('keydown', onEscape);
					};
				}
				return undefined;
			},
			[showPreview]
		);

		const layoutContainer = document.getElementById('layout-container') as HTMLElement;

		return (
			<>
				<div ref={containerRef} {...rest}>
					<Link
						to={watchRoute}
						className={className}
						aria-label={title}
						aria-hidden={hidden ? 'true' : undefined}
						tabIndex={hidden ? -1 : 0}
						onPointerMove={() => {
							if (!queueShowPreview.current) {
								queueShowPreview.current = window.setTimeout(() => setShowPreview(true), 500);
							}
						}}
						onPointerEnter={() => {
							window.clearTimeout(queueShowPreview.current);
							queueShowPreview.current = window.setTimeout(() => setShowPreview(true), 500);
						}}
						onPointerLeave={() => {
							window.clearTimeout(queueShowPreview.current);
						}}
					>
						<div className={clsx(classes.wrapper, continueWatching && classes.progressBarBottom)}>
							<img className={classes.thumbnail} src={thumbnail} alt={title} />
							<div className={classes.fallbackTextContainer} aria-hidden>
								<p className={classes.fallbackText}>{title}</p>
							</div>
						</div>
					</Link>
					{continueWatching && (
						<ProgressBar currentTime={continueWatching.currentTime} duration={continueWatching.duration} />
					)}
					<div className={classes.title}>{title}</div>
				</div>
				<Transition in={showPreview} mountOnEnter unmountOnExit timeout={{ enter: 10, exit: 250 }}>
					{state => {
						if (containerRef && containerRef.current) {
							const {
								top: tileTop,
								left: tileLeft,
								right: tileRight,
								width: tileWidth,
								height: tileHeight,
							} = containerRef.current.getBoundingClientRect();

							const getPreviewStyle = (): CSSProperties | undefined => {
								const previewWidth = Math.round(tileWidth * 1.5);
								const previewScale = 2 / 3;
								const previewScaleTransform = `scale(${previewScale})`;

								if (state === 'exited') {
									return {
										top: '-9999px',
										left: '-9999px',
										opacity: 0,
										width: previewWidth,
									};
								}
								const previewHeight = videoPreviewRef.current?.offsetHeight ?? 0;
								if (state === 'entering') {
									return {
										top: '-9999px',
										left: '-9999px',
										opacity: 0,
										width: previewWidth,
										transform: `translateY(${Math.round(
											((previewHeight * 2) / 3 - tileHeight) / 2
										)}px) ${previewScaleTransform}`,
										transitionDuration: 'none',
									};
								}
								const top = Math.round(tileTop + window.scrollY + tileHeight / 2 - previewHeight / 2);
								const left = Math.round(tileLeft + tileWidth / 2 - previewWidth / 2);
								if (state === 'exiting') {
									return {
										top,
										left,
										width: previewWidth,
										boxShadow: 'none',
										transform: `translateY(${Math.round(
											((previewHeight * 2) / 3 - tileHeight) / 2
										)}px) ${previewScaleTransform}`,
									};
								}
								if (state === 'entered') {
									const getEnteredTransform = () => {
										// less than left page gutter
										if (left < 60) {
											return `translateX(${tileLeft - left}px)`;
										}
										// greater than right page gutter
										if (left + previewWidth > getWindowRect().width - 60) {
											return `translateX(${tileRight - (left + previewWidth)}px)`;
										}
										return 'none';
									};
									return {
										top,
										left,
										width: previewWidth,
										transform: getEnteredTransform(),
										transition: 'transform 250ms ease-out',
									};
								}
								return undefined;
							};

							return (
								layoutContainer &&
								createPortal(
									<>
										<div
											onPointerMove={() => {
												window.clearTimeout(queueShowPreview.current);
												setShowPreview(false);
											}}
											className={classes.overlay}
											style={{
												display: state === 'entered' ? 'block' : 'none',
											}}
										/>
										<VideoPreviewSmall
											videoId={videoId}
											title={title}
											thumbnail={thumbnail}
											description={description}
											ref={videoPreviewRef}
											style={getPreviewStyle()}
											hidePreview={() => {
												window.clearTimeout(queueShowPreview.current);
												setShowPreview(false);
											}}
											exiting={state === 'exiting'}
										/>
									</>,
									layoutContainer
								)
							);
						}
						return null;
					}}
				</Transition>
			</>
		);
	}
);
