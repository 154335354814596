import { createContext, PropsWithChildren, useContext } from 'react';

import { ThemeProvider } from 'react-jss';
import { useParams } from 'react-router-dom';

import { ChurchBranding, ChurchBrandingKey, churchBranding } from './churchBranding';

const BrandingContext = createContext<ChurchBranding | null>(null);

export const BrandingProvider = ({ children }: PropsWithChildren) => {
	const { churchHandle = '' } = useParams<'churchHandle'>();
	const churchBrand = churchBranding[churchHandle as ChurchBrandingKey];

	if (!churchBrand) {
		return null;
	}

	return (
		<BrandingContext.Provider value={churchBrand}>
			<ThemeProvider theme={churchBrand.branding}>{children}</ThemeProvider>
		</BrandingContext.Provider>
	);
};

export function useBranding() {
	const context = useContext(BrandingContext);
	if (!context) {
		throw new Error('useBranding must be used inside a BrandingProvider');
	}
	return context;
}
