import { capitalCase } from 'capital-case';
import { useNavigate, useParams } from 'react-router-dom';

import { createUseStyles } from '@pushpay/styles';

import { useBranding } from '../../components/branding';
import { ArrowIcon } from '../../components/icons';
import { Theme } from '../../components/theme';
import { VideoTile } from '../../components/videoTile';
import { useContinueWatching, useTileColumnCount } from '../../utils';

type StyleParams = {
	columns: number;
};

const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		padding: '0 4%',
		marginTop: 'calc(61px + 2vw)',

		'@media screen and (min-width: 1500px)': {
			padding: '0 60px',
		},
	},
	heading: {
		fontWeight: 'bold',
		margin: 0,
		fontSize: '2.5rem',
		marginTop: '3vw',
	},
	gallery: ({ columns }: StyleParams) => ({
		marginTop: '1vw',
		display: 'grid',
		columnGap: '0.6vw',
		rowGap: '3vw',
		gridTemplateColumns: `repeat(${columns}, 1fr)`,
	}),
	backButton: {
		background: 'none',
		display: 'flex',
		alignItems: 'center',
		padding: 0,
		border: 0,
		fontSize: '16px',
		color: '#e5e5e5',
		cursor: 'pointer',

		'&:hover': {
			color: theme['color-text-default'],
		},
	},
	backButtonIcon: {
		display: 'flex',
		marginRight: '8px',
		transition: 'transform 150ms ease',

		'$backButton:hover &': {
			transform: 'translateX(-4px) scale(1.25)',
		},
	},
}));

export const ExplorePage = () => {
	const columns = useTileColumnCount();
	const classes = useStyles(undefined, { columns });
	const { getContinueWatchingVideos } = useContinueWatching();
	const navigate = useNavigate();

	const { category } = useParams<'category'>();
	const { playlists } = useBranding();

	function getPlaylist() {
		const isContinueWatching = category === 'continue-watching';
		if (isContinueWatching) {
			const continueWatchingVideos = getContinueWatchingVideos();
			if (!continueWatchingVideos.length) {
				return undefined;
			}
			return {
				name: capitalCase(category),
				videos: continueWatchingVideos,
			};
		}
		const playlist = playlists.find(p => p.name === capitalCase(category ?? ''));
		return playlist;
	}

	const playlist = getPlaylist();
	if (!playlist) {
		return null;
	}

	const { name, videos } = playlist;

	return (
		<div className={classes.container}>
			<div>
				<button
					aria-label="Back to browse"
					className={classes.backButton}
					type="button"
					onClick={() => navigate(-1)}
				>
					<ArrowIcon className={classes.backButtonIcon} />
					Back
				</button>
			</div>
			<h1 className={classes.heading}>{name}</h1>
			<div className={classes.gallery}>
				{videos.map(videoItem => (
					<VideoTile key={videoItem.videoId} {...videoItem} />
				))}
			</div>
		</div>
	);
};
