import { clsx, createUseStyles, multiply } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { Theme } from '../theme';

const useStyles = createUseStyles((theme: Theme) => ({
	progressContainer: {
		height: '4px',
		width: '100%',
		backgroundColor: '#676767',
		position: 'relative',
	},
	progressCompleted: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '4px',
		backgroundColor: theme['brand-primary-color'],
		minWidth: '2%',
	},
}));

export const ProgressBar = ({
	currentTime,
	duration,
	classes: classesProp,
	className: classNameProp,
}: ComponentProps<{ currentTime: number; duration: number }, typeof useStyles>) => {
	const classes = useStyles(classesProp);
	const className = clsx(classes.progressContainer, classNameProp);

	return (
		<div className={className}>
			<div className={classes.progressCompleted} style={{ width: multiply('100%', currentTime / duration) }} />
		</div>
	);
};
