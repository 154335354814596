import { clsx, createUseStyles } from '@pushpay/styles';

import { Icon, IconProps } from './Icon';

type Direction = 'left' | 'right' | 'up' | 'down';
type DirectionAngles = Record<Direction, string>;

const directionAngles: DirectionAngles = {
	up: '0deg',
	right: '90deg',
	down: '180deg',
	left: '-90deg',
};

const useStyles = createUseStyles({
	root: ({ direction }: { direction: Direction }) => ({
		transform: `rotate(${directionAngles[direction]})`,
	}),
});

type ArrowSingleIconProps = IconProps & { direction: Direction };

export const ArrowSingleIcon = ({
	direction,
	classes: classesProp,
	className: classNameProp,
	...rest
}: ArrowSingleIconProps) => {
	const classes = useStyles(classesProp, { direction });
	const className = clsx(classes.root, classNameProp);
	return (
		<Icon viewBox="0 0 24 24" className={className} {...rest}>
			<path
				d="M12 6c.55 0 1.074.217 1.437.599l8.157 8.556a1.342 1.342 0 01-.089 2.003c-.623.53-1.595.494-2.17-.081l-.047-.05-7.132-7.482a.22.22 0 00-.312 0l-7.132 7.483c-.546.6-1.514.678-2.164.175-.649-.502-.734-1.397-.19-1.997l.048-.05 8.154-8.554C10.924 6.219 11.449 6 12 6z"
				fill="currentColor"
				fillRule="nonzero"
			/>
		</Icon>
	);
};
