import cityRevChurchHero from '../../images/cityrevchurch/CityRevChurchHero.jpg';
import cityRevChurchLogo from '../../images/cityrevchurch/CityRevChurchLogo.png';
import fellowshipChurchHero from '../../images/fellowshipchurch/FellowshipChurchHero.jpeg';
import fellowshipChurchLogo from '../../images/fellowshipchurch/FellowshipChurchLogo.png';
import higherDimensionChurchHero from '../../images/higherdimensionchurch/HigherDimensionChurchHero.jpg';
import higherDimensionChurchLogo from '../../images/higherdimensionchurch/HigherDimensionChurchLogo.png';
import redRocksChurchHero from '../../images/redrockschurch/RedRocksChurchHero.jpg';
import redRocksChurchLogo from '../../images/redrockschurch/RedRocksChurchLogo.png';
import timesSquareChurchHero from '../../images/timessquarechurch/TimesSquareChurchHero.jpg';
import timesSquareChurchLogo from '../../images/timessquarechurch/TimesSquareChurchLogo.png';
import { demoPlaylists, timesSquareChurchPlaylists } from '../../playlists';

export const churchBranding = {
	cityrevchurch: {
		name: 'City Rev Church',
		website: {
			name: 'cityrev.org',
			url: 'https://cityrev.org',
		},
		branding: {
			'brand-primary-color': '#ed917d',
			'brand-secondary-color': '#9ad0c5',
			'brand-text-color': '#fffcfa',
		},
		hero: {
			title: 'Show No Partiality',
			description:
				'Pastor Dan Gossett speaks on this idea of partiality and how we should treat those around us.',
			src: cityRevChurchHero,
		},
		logo: cityRevChurchLogo,
		playlists: demoPlaylists,
	},
	fellowshipchurch: {
		name: 'Fellowship Church',
		website: {
			name: 'fellowshipchurch.com',
			url: 'https://www.fellowshipchurch.com',
		},
		branding: {
			'brand-primary-color': '#00a8e9',
			'brand-secondary-color': '#cccccc',
			'brand-text-color': '#ffffff',
		},
		hero: {
			title: 'What does it mean to be "Woke?"',
			description: 'In this sermon, Pastor Ed Young brings a biblical perspective to this controversial topic.',
			src: fellowshipChurchHero,
		},
		logo: fellowshipChurchLogo,
		playlists: demoPlaylists,
	},
	higherdimensionchurch: {
		name: 'Higher Dimension Church',
		website: {
			name: 'higherd.net',
			url: 'https://www.higherd.net',
		},
		branding: {
			'brand-primary-color': '#e7cb3a',
			'brand-secondary-color': '#cccccc',
			'brand-text-color': '#f3f3ed',
		},
		hero: {
			title: 'The Power of Capacity',
			description: 'We’re excited to share this message with you.',
			src: higherDimensionChurchHero,
		},
		logo: higherDimensionChurchLogo,
		playlists: demoPlaylists,
	},
	redrockschurch: {
		name: 'Red Rocks Church',
		website: {
			name: 'redrockschurch.com',
			url: 'https://www.redrockschurch.com',
		},
		branding: {
			'brand-primary-color': '#eb4924',
			'brand-secondary-color': '#cccccc',
			'brand-text-color': '#ffffff',
		},
		hero: {
			title: 'Grace In A "Get What You Deserve" World',
			description: 'Shawn Johnson speaks about the unmerited, unearned, undeserved favor and kindness from God.',
			src: redRocksChurchHero,
		},
		logo: redRocksChurchLogo,
		playlists: demoPlaylists,
	},
	timessquarechurch: {
		name: 'Times Square Church',
		website: {
			name: 'tsc.nyc',
			url: 'https://tsc.nyc',
		},
		branding: {
			'brand-primary-color': '#e00035',
			'brand-secondary-color': '#cccccc',
			'brand-text-color': '#ffffff',
		},
		hero: {
			title: 'From Green Pastures to Dark Valleys',
			description:
				'Pastor Tim Dilena preaches through Psalms 23 and how David went from knowing of God (third person) to a relationship with God (second person).',
			src: timesSquareChurchHero,
		},
		logo: timesSquareChurchLogo,
		playlists: timesSquareChurchPlaylists,
	},
};

export type ChurchBrandingKey = keyof typeof churchBranding;
export type ChurchBranding = typeof churchBranding[keyof typeof churchBranding];
export type Branding = ChurchBranding['branding'];
