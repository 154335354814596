export function shuffleArray<T>(originalArray: T[]) {
	const shuffledArray = originalArray.slice();
	let currentIndex = shuffledArray.length;
	let randomIndex;

	// While there remain elements to shuffle.
	while (currentIndex !== 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[shuffledArray[currentIndex], shuffledArray[randomIndex]] = [
			shuffledArray[randomIndex],
			shuffledArray[currentIndex],
		];
	}

	return shuffledArray;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined;
}

/**
 * @param key item property name used for deduping
 * @param items items to include in the list. Later items override earler ones.
 * @returns an array of unique items
 */
export function getUniqueArrayBy<T>(key: keyof T, ...items: T[]) {
	return Array.from(new Map(items.map(item => [item[key], item])).values());
}
