import { createUseStyles, divide, CSSProperties } from '@pushpay/styles';

import { Theme } from '../../components/theme';

export const useStyles = createUseStyles((theme: Theme) => ({
	'@global': {
		'html, body': {
			width: '100%',
			height: '100%',
			margin: 0,
			padding: 0,
			position: 'relative',
			fontSize: '10px',

			'@media only screen and (max-height: 720px)': {
				fontSize: '9px',
			},

			'@media only screen and (max-height: 648px)': {
				fontSize: '8px',
			},

			'@media only screen and (max-height: 576px)': {
				fontSize: '7.2px',
			},

			'@media only screen and (max-height: 540px)': {
				fontSize: '6.6px',
			},

			'@media only screen and (max-height: 480px)': {
				fontSize: '6px',
			},

			'@media only screen and (max-height: 360px)': {
				fontSize: '5.3px',
			},

			'@media only screen and (max-height: 240px)': {
				fontSize: '4.8px',
			},
		},
		'.resi-player': {
			'& #aspect-ratio': {
				position: 'static !important' as CSSProperties['position'],
			},
			'& .body4': {
				fontSize: '1.6rem !important',
				fontFamily: `${theme['typography-font-family']} !important`,
			},
			'& .icon-button': {
				transition: 'transform 150ms ease',
				willChange: 'width, height',
				'&:hover': {
					transform: 'scale(1.3)',
				},
			},
		},
	},
	watchVideo: {
		height: '100%',
		left: '0',
		margin: '0',
		overflow: 'hidden',
		padding: '0',
		position: 'absolute',
		top: '0',
		width: '100%',
	},
	videoContainer: {
		position: 'absolute',
		width: '100%',
		height: '100%',
	},
	videoContainerLoading: {
		display: 'none',
	},
	overlay: {
		height: '100%',
		left: '0',
		margin: '0',
		overflow: 'hidden',
		padding: '0',
		position: 'absolute',
		top: '0',
		width: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	overlayExiting: {
		opacity: 0,
		transition: 'opacity 500ms ease-in',
	},
	overlayTextContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		color: theme['color-text-default'],
		height: '100%',
		width: '100%',
		padding: '12%',
		position: 'relative',
		userSelect: 'none',
	},
	gradient: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		background: 'linear-gradient(to bottom,rgba(0,0,0,.65098),rgba(0,0,0,0))',
		height: divide('100em', 9),
	},
	loadingTitleContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		margin: '3.5em 4.5em',
	},
	loadingTitle: {
		margin: 0,
		fontSize: '3.3em',
	},
	youreWatching: {
		fontSize: '1.8rem',
		margin: '0px 0px 0px 0.4rem',
		color: '#CCC',
	},
	title: {
		fontSize: '5.4rem',
		color: theme['color-text-default'],
		fontWeight: 'bold',
		marginBottom: '0px',
		marginTop: '0px',
	},
	duration: {
		fontSize: '2.4rem',
		margin: '0.6rem 0px 1.2rem',
	},
	synopsis: {
		fontSize: '1.8rem',
		color: 'rgb(204, 204, 204)',
		width: '60%',
		margin: '0px',
	},
	paused: {
		fontSize: '1.8rem',
		fontWeight: 'normal',
		WebkitFontSmoothing: 'antialiased',
		color: 'rgb(204, 204, 204)',
		position: 'absolute',
		bottom: '10%',
		right: '10%',
	},
	loadingSpinner: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate3d(-50%, -50%, 0)',
		fontSize: '10vh',
	},
	backButtonContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		paddingLeft: '3rem',
		paddingRight: '3rem',
		paddingTop: '3rem',
		alignItems: 'normal',
		flexGrow: '1',
		justifyContent: 'flex-start',
		transition: 'opacity 300ms',
		opacity: 0,
		visibility: 'hidden',
	},
	backButtonContainerEntering: {
		opacity: 1,
		visibility: 'visible',
	},
	backButton: {
		color: theme['color-text-default'],
		background: 'none',
		fontSize: '4.4rem',
		cursor: 'pointer',
		border: 0,
		transition: 'transform 150ms ease',
		willChange: 'width, height',

		'&:hover': {
			transform: 'scale(1.1)',
		},
	},
}));
