import { ReactNode, TransitionEventHandler, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import { add, clsx, createUseStyles, divide, subtract } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { useTileColumnCount } from '../../utils/useTileBreakpoints';
import { ArrowSingleIcon } from '../icons';
import { Theme } from '../theme';
import { SliderItem } from './SliderItem';

type StyleParams = {
	displayCount: number;
	translateX: string;
};

const useStyles = createUseStyles((theme: Theme) => ({
	sliderWrapper: {
		position: 'relative',
		padding: '0 4%',
		touchAction: 'pan-y',
		zIndex: 2,

		'@media screen and (min-width: 1500px)': {
			padding: '0 60px',
		},
	},
	sliderMask: {
		overflowX: 'visible',
		paddingBottom: '1px',
		whiteSpace: 'nowrap',
	},
	sliderContent: ({ translateX }: StyleParams) => ({
		transform: `translate3d(${translateX}, 0, 0)`,
	}),
	sliderContentSliding: {
		transition: 'transform 0.75s ease',
	},
	sliderItem: ({ displayCount }: StyleParams) => ({
		width: `calc(${divide('100%', displayCount)} - 0.6vw)`,
	}),
	button: {
		color: theme['color-text-default'],
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '4%',
		zIndex: 3,
		position: 'absolute',
		top: 0,
		bottom: 0,
		background: 'rgba(20, 20, 20, 0.5)',
		border: 0,
		cursor: 'pointer',

		'@media screen and (min-width: 1500px)': {
			width: '60px',
		},
	},
	buttonIconWrapper: {
		marginTop: '-36px',
		transition: 'transform .1s ease-out',

		'&:hover': {
			transform: 'scale(1.25)',
		},
	},
	next: {
		right: 0,
		borderTopLeftRadius: '4px',
		borderBottomLeftRadius: '4px',
	},
	prev: {
		left: 0,
		borderTopRightRadius: '4px',
		borderBottomRightRadius: '4px',
	},
	buttonIcon: {
		fontSize: '3rem',
	},
}));

type RenderItemProps<T> = { item: T; hidden?: boolean };
type SliderProps<T> = ComponentProps<
	{ items: T[]; renderItem: (props: RenderItemProps<T>) => ReactNode; children?: never },
	typeof useStyles
>;

export const Slider = function Slider<T>({
	classes: classesProp,
	className: classNameProp,
	renderItem,
	items: itemsProp,
}: SliderProps<T>) {
	const displayCount = useTileColumnCount();
	const totalCount = itemsProp.length;
	const showNext = itemsProp.length > displayCount;
	const [translateX, setTranslateX] = useState('0%');
	const [sliding, setSliding] = useState(false);
	const [showPrev, setShowPrev] = useState(false);
	const contentRef = useRef<HTMLDivElement>(null);
	const currentSlide = useRef(0);

	const takeItems = useCallback(
		(from: number, take: number) => Array.from(new Array(take), (_, i) => itemsProp[(from + i) % totalCount]),
		[itemsProp, totalCount]
	);

	const [windowedItems, setWindowedItems] = useState(() =>
		showNext ? takeItems(currentSlide.current, displayCount * 2 + 1) : itemsProp
	);

	const getPreviousIndex = useCallback(
		(previousBy: number) => (totalCount + (currentSlide.current - previousBy)) % totalCount,
		[totalCount]
	);

	const onTransitionEnd: TransitionEventHandler = useCallback(
		({ target, currentTarget }) => {
			if (target !== currentTarget) return;
			setTranslateX(subtract('-100%', 100 / displayCount));
			setWindowedItems(() => takeItems(getPreviousIndex(displayCount + 1), displayCount * 3 + 2));
			setSliding(false);
			setShowPrev(true);
		},
		[displayCount, getPreviousIndex, takeItems]
	);

	useUpdateEffect(
		function updateItemsProp() {
			if (showPrev) {
				setWindowedItems(takeItems(getPreviousIndex(displayCount + 1), displayCount * 3 + 2));
				return;
			}
			if (showNext) {
				setWindowedItems(takeItems(currentSlide.current, displayCount * 2 + 1));
				return;
			}
			setWindowedItems(itemsProp);
		},
		[itemsProp]
	);

	useLayoutEffect(
		function focusFirstInteractiveChild() {
			if (showPrev) {
				contentRef.current?.querySelector<HTMLElement>('[tabindex="0"]')?.focus();
			}
		},
		[windowedItems, showPrev]
	);

	const slideNext = useCallback(() => {
		setSliding(true);
		setTranslateX(current => subtract(current, '100%'));
		currentSlide.current = (currentSlide.current + displayCount) % totalCount;
	}, [displayCount, totalCount]);

	const slidePrev = useCallback(() => {
		setSliding(true);
		setTranslateX(current => add(current, '100%'));
		currentSlide.current = getPreviousIndex(displayCount);
	}, [displayCount, getPreviousIndex]);

	const classes = useStyles(classesProp, { displayCount, translateX });
	const className = clsx(classes.sliderWrapper, classNameProp);
	const contentClassName = clsx(classes.sliderContent, sliding && classes.sliderContentSliding);

	function isHidden(index: number) {
		if (showNext && index >= windowedItems.length - (displayCount + 1)) {
			return true;
		}
		if (showPrev && index <= displayCount) {
			return true;
		}
		return false;
	}

	return (
		<div className={className}>
			{showPrev && (
				<button
					type="button"
					onClick={slidePrev}
					className={clsx(classes.button, classes.prev)}
					disabled={sliding}
					aria-label="See previous"
				>
					<span className={clsx(classes.buttonIconWrapper, classes.prev)}>
						<ArrowSingleIcon direction="left" className={classes.buttonIcon} />
					</span>
				</button>
			)}
			<div className={classes.sliderMask}>
				<div ref={contentRef} className={contentClassName} onTransitionEnd={onTransitionEnd}>
					{windowedItems.map((item, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<SliderItem key={index} className={classes.sliderItem}>
							{renderItem({ item, hidden: isHidden(index) })}
						</SliderItem>
					))}
				</div>
			</div>
			{showNext && (
				<button
					type="button"
					onClick={slideNext}
					className={clsx(classes.button, classes.next)}
					disabled={sliding}
					aria-label="See more"
				>
					<span className={clsx(classes.buttonIconWrapper, classes.next)}>
						<ArrowSingleIcon direction="right" className={classes.buttonIcon} />
					</span>
				</button>
			)}
		</div>
	);
};
