import { useRef, useState } from 'react';

import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';

import { clsx, createUseStyles } from '@pushpay/styles';

import { CrossIcon, SearchIcon } from '../icons';
import { Theme } from '../theme';

const useStyles = createUseStyles((theme: Theme) => ({
	root: {},
	button: {
		color: theme['color-text-default'],
		backgroundColor: 'transparent',
		border: 0,
		cursor: 'pointer',
		display: 'flex',
		borderRadius: '3px',
	},
	searchIcon: {
		fontSize: '26px',
	},
	openWrapper: {
		position: 'relative',
		border: '1px solid #FFF',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: 'rgba(0,0,0,0.7)',
		padding: '0 24px 0 4px',
	},
	clearButton: {
		fontSize: '22px',
		position: 'absolute',
		right: '2px',
		padding: 0,
	},
	clearIcon: {
		fontSize: '22px',
	},
	input: {
		fontSize: '15px',
		background: 'none',
		border: 0,
		padding: '0 4px',
		lineHeight: '30px',
		color: theme['color-text-default'],
		outline: 'none',
		animation: '$expand 250ms ease forwards',
	},
	'@keyframes expand': {
		'0%': {
			width: 0,
		},
		'100%': {
			width: '250px',
		},
	},
}));

export function SearchBox() {
	const classes = useStyles(undefined);

	const [searchParams, setSearchParams] = useSearchParams();
	const searchTerm = searchParams.get('q');
	const [isOpen, setIsOpen] = useState(!!searchTerm);
	const containerRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(containerRef, () => {
		if (searchTerm) {
			return;
		}
		setIsOpen(false);
	});

	const { churchHandle } = useParams<'churchHandle'>();
	const navigate = useNavigate();

	if (isOpen) {
		const inputValue = searchParams.get('q') ?? '';
		return (
			<div className={classes.openWrapper} ref={containerRef}>
				<SearchIcon className={classes.searchIcon} />
				<input
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
					type="text"
					spellCheck={false}
					className={classes.input}
					placeholder="Titles or description"
					value={inputValue}
					onChange={ev => {
						const newValue = ev.currentTarget.value;
						if (!newValue) {
							navigate(-1);
							return;
						}
						if (inputValue) {
							searchParams.set('q', newValue);
							setSearchParams(searchParams, { replace: true });
							return;
						}
						navigate(
							{
								pathname: generatePath('/:churchHandle/search', { churchHandle }),
								search: `q=${newValue}`,
							},
							{
								replace: false,
							}
						);
					}}
				/>
				{searchTerm && (
					<button
						type="button"
						onClick={() => {
							searchParams.delete('q');
							setSearchParams(searchParams, { replace: true });
							navigate(-1);
						}}
						className={clsx(classes.button, classes.clearButton)}
					>
						<CrossIcon />
					</button>
				)}
			</div>
		);
	}

	return (
		<button
			type="button"
			aria-label="Search"
			onClick={() => setIsOpen(true)}
			className={clsx(classes.button, classes.searchIcon)}
		>
			<SearchIcon />
		</button>
	);
}
