import { useDeferredValue, useMemo, useRef } from 'react';

import { Searcher } from 'fast-fuzzy';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { createUseStyles } from '@pushpay/styles';

import { useBranding } from '../../components/branding';
import { ArrowIcon } from '../../components/icons';
import { Theme } from '../../components/theme';
import { VideoTile } from '../../components/videoTile';
import { useTileColumnCount } from '../../utils';
import { getUniqueArrayBy } from '../../utils/arrayHelpers';

type StyleParams = {
	columns: number;
};

const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		padding: '0 4%',
		marginTop: 'calc(61px + 2vw)',

		'@media screen and (min-width: 1500px)': {
			padding: '0 60px',
		},
	},
	heading: {
		fontWeight: 'bold',
		margin: 0,
		fontSize: '2.5rem',
		marginTop: '3vw',
	},
	gallery: ({ columns }: StyleParams) => ({
		marginTop: '1vw',
		display: 'grid',
		columnGap: '0.6vw',
		rowGap: '3vw',
		gridTemplateColumns: `repeat(${columns}, 1fr)`,
	}),
	backButton: {
		background: 'none',
		display: 'flex',
		alignItems: 'center',
		padding: 0,
		border: 0,
		fontSize: '16px',
		color: '#e5e5e5',
		cursor: 'pointer',

		'&:hover': {
			color: theme['color-text-default'],
		},
	},
	backButtonIcon: {
		display: 'flex',
		marginRight: '8px',
		transition: 'transform 150ms ease',

		'$backButton:hover &': {
			transform: 'translateX(-4px) scale(1.25)',
		},
	},
}));

export function SearchPage() {
	const columns = useTileColumnCount();
	const classes = useStyles(undefined, { columns });

	const [searchParams] = useSearchParams();
	const searchTerm = searchParams.get('q') ?? '';
	const navigate = useNavigate();

	const { playlists } = useBranding();
	const searcher = useRef(
		new Searcher(getUniqueArrayBy('videoId', ...playlists.flatMap(playlist => playlist.videos)), {
			threshold: 0.8,
			keySelector({ title, description }) {
				return [title, description];
			},
		})
	);
	const deferredSearchTerm = useDeferredValue(searchTerm);

	const gallery = useMemo(() => {
		const videoSearchResults = searcher.current.search(deferredSearchTerm);
		return (
			<div className={classes.gallery}>
				{videoSearchResults.map(videoSearchResult => (
					<VideoTile key={videoSearchResult.videoId} {...videoSearchResult} />
				))}
			</div>
		);
	}, [classes, deferredSearchTerm]);

	return (
		<div className={classes.container}>
			<button
				aria-label="Back to browse"
				className={classes.backButton}
				type="button"
				onClick={() => navigate(-1)}
			>
				<ArrowIcon className={classes.backButtonIcon} />
				Back
			</button>
			<h1 className={classes.heading}>
				Videos matching {deferredSearchTerm && <>&quot;{deferredSearchTerm}&quot;</>}
			</h1>
			{gallery}
		</div>
	);
}
