import { Icon, IconProps } from './Icon';

export const CrossIcon = (props: IconProps) => (
	<Icon viewBox="0 0 24 24" {...props}>
		<path
			d="M13.533 12.119a.167.167 0 010-.236l6.176-6.175a1 1 0 10-1.416-1.414l-6.175 6.172a.167.167 0 01-.236 0L5.707 4.294a1 1 0 00-1.414 1.414l6.174 6.175a.167.167 0 010 .236l-6.174 6.175a1 1 0 001.414 1.415l6.175-6.176a.167.167 0 01.236 0l6.175 6.176a1 1 0 101.414-1.415l-6.174-6.175z"
			fill="currentColor"
		/>
	</Icon>
);
