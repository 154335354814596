import { PropsWithChildren } from 'react';

import { BrowserRouter, Route, Routes, useLocation, matchPath, Location, Outlet } from 'react-router-dom';
import { Transition, SwitchTransition, TransitionStatus } from 'react-transition-group';

import { clsx, createUseStyles } from '@pushpay/styles';

import { BrandingProvider } from './components/branding';
import { CssReset } from './components/cssReset';
import { Layout } from './components/layout';
import { ThemeProvider } from './components/theme';
import { ExplorePage, HomePage, SearchPage, WatchPage } from './pages';

const useStyles = createUseStyles({
	pageWrapper: {
		transition: 'opacity 200ms ease-out',
	},
	enter: {
		opacity: 0,
	},
	entering: {
		opacity: 1,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {},
	unmounted: {},
});

function PageTransition({ location, children }: PropsWithChildren<{ location: Location }>) {
	const classes = useStyles(undefined);
	return (
		<SwitchTransition mode="out-in">
			<Transition key={location.pathname} timeout={200} onEnter={() => window.scrollTo(0, 0)} unmountOnExit>
				{pageState => (
					<Routes location={location}>
						<Route
							element={
								<div className={clsx(classes.pageWrapper, classes[pageState])}>
									<Outlet />
								</div>
							}
						>
							{children}
						</Route>
					</Routes>
				)}
			</Transition>
		</SwitchTransition>
	);
}

const AppCore = () => {
	const location = useLocation();
	const isWatch = !!matchPath(':churchHandle/watch/:videoId', location.pathname);

	return (
		<SwitchTransition mode="out-in">
			<Transition key={isWatch ? 'watch' : 'layout'} timeout={{ exit: isWatch ? 0 : 750 }} unmountOnExit>
				{state => (
					<Layout exiting={state === 'exiting'} hide={isWatch}>
						<Routes location={location}>
							<Route path="watch/:videoId" element={<WatchPage />} />
							<Route
								path="*"
								element={
									<PageTransition location={location}>
										<Route path="search" element={<SearchPage />} />
										<Route path="explore/:category" element={<ExplorePage />} />
										<Route path="*" element={<HomePage />} />
									</PageTransition>
								}
							/>
						</Routes>
					</Layout>
				)}
			</Transition>
		</SwitchTransition>
	);
};

export function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path=":churchHandle/*"
					element={
						<BrandingProvider>
							<ThemeProvider>
								<CssReset />
								<AppCore />
							</ThemeProvider>
						</BrandingProvider>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
}
