import { clsx, createUseStyles } from '@pushpay/styles';

import { Icon, IconProps } from './Icon';

const directionAngles = {
	left: '0deg',
	up: '90deg',
	right: '180deg',
	down: '-90deg',
};

type ArrowIconProps = {
	/**
	 * @default 'left'
	 */
	direction?: keyof typeof directionAngles;
};

const useStyles = createUseStyles({
	root: ({ direction }: { direction: keyof typeof directionAngles }) => ({
		transform: `rotate(${directionAngles[direction]})`,
	}),
});

export const ArrowIcon = ({
	direction = 'left',
	classes: classesProp,
	className: classNameProp,
	...props
}: IconProps & ArrowIconProps) => {
	const classes = useStyles(classesProp, { direction });
	const className = clsx(classes.root, classNameProp);

	return (
		<Icon className={className} viewBox="0 0 24 24" {...props}>
			<path
				d="M20.828 10.8H6.53a.21.21 0 01-.138-.366L13.61 4.08a1.204 1.204 0 00-1.588-1.808l-9.343 8.226a2.007 2.007 0 000 3.01l9.341 8.225a1.203 1.203 0 001.588-1.807L6.39 13.572a.21.21 0 01.138-.366h14.3a1.204 1.204 0 000-2.407v.001z"
				fill="currentColor"
				fillRule="nonzero"
			/>
		</Icon>
	);
};
