import { createUseStyles } from '@pushpay/styles';

import resiLogo from '../../images/ResiLogo.png';
import { useBranding } from '../branding';
import { ArrowIcon, PushpayLogoIcon } from '../icons';
import { Theme } from '../theme';

const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		padding: '0 4%',
		margin: '4vw 0 3vw 0',
		color: '#737478',

		'@media screen and (max-width: 950px)': {
			marginBottom: '40px',
		},

		'@media screen and (min-width: 1500px)': {
			padding: '0 60px',
		},
	},
	topRow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingBottom: '18px',
		borderBottom: '1px solid #737478',

		'@media screen and (max-width: 950px)': {
			flexDirection: 'column-reverse',
		},
	},
	bottomRow: {
		fontSize: '13px',
		marginTop: '14px',
	},
	logoContainer: {
		display: 'flex',
		alignItems: 'center',

		'@media screen and (max-width: 950px)': {
			marginTop: '20px',
		},
	},
	logoLink: {
		transition: 'transform 100ms ease',

		'&:hover': {
			transform: 'scale(1.05)',
		},
	},
	resiLogo: {
		marginTop: '-6px',
	},
	pushpayLogo: {
		fontSize: '28px',
		color: '#929292',
	},
	link: {
		fontSize: '16px',
		display: 'flex',
		alignItems: 'center',
		color: '#E5E5E5',
		textDecoration: 'none',

		'&:hover': {
			color: theme['color-text-default'],
		},
	},
	linkArrow: {
		display: 'flex',
		marginLeft: '8px',
		transition: 'transform 150ms ease',

		'$link:hover &': {
			transform: 'translateX(4px) scale(1.25)',
		},
	},
	logoDivider: {
		width: '1px',
		height: '24px',
		backgroundColor: '#737478',
		margin: '0 20px',
	},
}));

export const Footer = () => {
	const classes = useStyles(undefined);
	const { website } = useBranding();

	return (
		<div className={classes.container}>
			<div className={classes.topRow}>
				<div className={classes.logoContainer}>
					<a href="https://resi.io" className={classes.logoLink} aria-label="Go to Resi Media LLC">
						<img src={resiLogo} alt="Resi" className={classes.resiLogo} />
					</a>
					<div className={classes.logoDivider} />
					<a href="https://pushpay.com" className={classes.logoLink} aria-label="Go to Pushpay Ltd.">
						<PushpayLogoIcon className={classes.pushpayLogo} />
					</a>
				</div>
				<a href={website.url} className={classes.link} target="_blank" rel="noopener noreferrer">
					Go to&nbsp;<strong>{website.name}</strong>
					<span className={classes.linkArrow}>
						<ArrowIcon direction="right" />
					</span>
				</a>
			</div>
			<div className={classes.bottomRow}>
				&copy; Resi Media LLC and Pushpay<sup>&reg;</sup> Ltd. All rights reserved.
			</div>
		</div>
	);
};
