import { forwardRef, Ref, useState } from 'react';

import FocusTrap from 'focus-trap-react';
import { generatePath, Link, useParams } from 'react-router-dom';

import { clsx, createUseStyles, multiply } from '@pushpay/styles';
import { ComponentProps } from '@pushpay/types';

import { useContinueWatching } from '../../utils';
import { AnimatedTickIcon, CrossIcon, PlayIcon, PlusIcon } from '../icons';
import { ProgressBar } from '../progressBar';
import { Theme } from '../theme';

const useStyles = createUseStyles((theme: Theme) => ({
	container: {
		position: 'absolute',
		zIndex: 3,
		transform: `scale(${2 / 3})`,
		backgroundColor: 'transparent',
		borderRadius: '6px',
		fontSize: '16px',
		transformOrigin: 'center center',
		boxShadow: 'rgba(0, 0, 0, 0.75) 0px 3px 10px',
		willChange: 'transform',
		opacity: 1,
		transition: 'transform 250ms ease-out, opacity 250ms ease-out',
	},
	previewImageContainer: {
		position: 'relative',
		borderTopLeftRadius: '6px',
		borderTopRightRadius: '6px',
		overflow: 'hidden',
	},
	previewImageWrapper: {
		height: '100%',
		paddingTop: multiply('100%', 9 / 16), // 16:9 ratio
		width: '100%',
	},
	thumbnailImg: {
		backgroundSize: 'cover',
		height: '100%',
		left: '0',
		position: 'absolute',
		top: '0',
		width: '100%',
	},
	info: {
		transform: 'none',
		opacity: 1,
		backgroundColor: '#191919',
		padding: '16px',
		borderBottomLeftRadius: '6px',
		borderBottomRightRadius: '6px',
	},
	infoExiting: {
		transition: 'opacity 100ms 150ms ease-in',
		opacity: 0,
	},
	link: {
		color: theme['color-text-default'],
		textDecoration: 'none',
	},
	controls: {
		display: 'flex',
	},
	control: {
		borderRadius: '50%',
		backgroundColor: 'transparent',
		padding: 0,
		cursor: 'pointer',
		width: '30px',
		height: '30px',
		marginLeft: '8px',
		display: 'flex',
		flex: 'none',
		justifyContent: 'center',
		alignItems: 'center',
	},
	play: {
		fontSize: '24px',
		backgroundColor: '#FFF',
		color: theme['color-text-default'],

		'&:hover, &:focus': {
			backgroundColor: '#E6E6E6',
		},
	},
	playIcon: {
		color: '#000',
	},
	controlCircular: {
		border: '2px solid rgba(255, 255, 255, 0.7)',
		color: theme['color-text-default'],
		fontSize: '18px',

		'&:hover': {
			backgroundColor: '#2A2A2A',
			borderColor: '#FFF',
		},

		'&:focus': {
			backgroundColor: '#2A2A2A',
		},
	},
	addToMyListIcon: {},
	addedToMyListIcon: {
		left: '4px',
		width: '27px',
		bottom: '1px',
		height: '24px',
	},
	title: {
		fontSize: '18px',
		lineHeight: 1.5,
		lineClamp: 2,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		boxOrient: 'vertical',
		fontWeight: 'bold',
		marginBottom: '8px',
	},
	description: {
		lineHeight: 1.5,
		lineClamp: 4,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		boxOrient: 'vertical',
		marginBottom: '8px',
	},
	progressContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	progress: {
		flexShrink: 0,
		marginLeft: '6px',
		color: 'rgba(255, 255, 255, 0.7)',
	},
}));

export const VideoPreviewSmall = forwardRef(function VideoPreviewSmall(
	{
		videoId,
		title,
		thumbnail,
		exiting,
		description,
		hidePreview,
		style,
	}: ComponentProps<
		{
			exiting: boolean;
			videoId: string;
			title: string;
			thumbnail: string;
			description: string;
			hidePreview: () => void;
		},
		typeof useStyles
	>,
	ref: Ref<HTMLDivElement>
) {
	const classes = useStyles(undefined);
	// const [inMyList, setInMyList] = useState(false);
	const { churchHandle } = useParams<'churchHandle'>();
	const watchRoute = generatePath('/:churchHandle/watch/:videoId', { churchHandle, videoId });
	const { getContinueWatchingVideo, removeContinueWatchingVideo } = useContinueWatching();
	const continueWatching = getContinueWatchingVideo(videoId);

	return (
		<FocusTrap active={false} focusTrapOptions={{ initialFocus: false, allowOutsideClick: true }}>
			<div ref={ref} role="dialog" aria-modal tabIndex={-1} style={style} className={classes.container}>
				<Link to={watchRoute} tabIndex={-1} className={classes.link}>
					<div className={classes.previewImageContainer}>
						<div className={classes.previewImageWrapper}>
							<img className={classes.thumbnailImg} src={thumbnail} alt={title} />
						</div>
					</div>
				</Link>
				<div className={clsx(classes.info, exiting && classes.infoExiting)}>
					<div className={classes.title}>{title}</div>
					{/* {continueWatching && (
						<div className={classes.controls}>
							<Link to={watchRoute} className={clsx(classes.control, classes.play)}>
								<PlayIcon className={classes.playIcon} />
							</Link>
							<button
							type="button"
							className={clsx(classes.control, classes.controlCircular)}
							onClick={() => setInMyList(currentlyInMyList => !currentlyInMyList)}
						>
							{inMyList ? (
								<AnimatedTickIcon className={classes.addedToMyListIcon} />
							) : (
								<PlusIcon className={classes.addToMyListIcon} />
							)}
						</button>
							{continueWatching && (
								<button
									type="button"
									onClick={() => {
										hidePreview();
										window.setTimeout(() => removeContinueWatchingVideo(videoId), 250);
									}}
									className={clsx(classes.control, classes.controlCircular)}
								>
									<CrossIcon />
								</button>
							)}
						</div>
					)} */}
					<div className={classes.description}>{description}</div>
					{continueWatching && (
						<div className={classes.progressContainer}>
							<ProgressBar
								currentTime={continueWatching.currentTime}
								duration={continueWatching.duration}
							/>
							<div className={classes.progress}>
								{Math.ceil(continueWatching.currentTime / 60)} of{' '}
								{Math.ceil(continueWatching.duration / 60)}m
							</div>
							<button
								title="Remove from Continue Watching"
								type="button"
								onClick={() => {
									hidePreview();
									window.setTimeout(() => removeContinueWatchingVideo(videoId), 250);
								}}
								className={clsx(classes.control, classes.controlCircular)}
							>
								<CrossIcon />
							</button>
						</div>
					)}
				</div>
			</div>
		</FocusTrap>
	);
});
