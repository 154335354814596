import { useReducer } from 'react';

type VideoPlayerState = (
	| { playerState: 'loading' }
	| {
			playerState: 'playing' | 'paused';
			duration: string;
			videoContainer: HTMLElement;
	  }
) & { playerKey: number };

type VideoPlayerAction =
	| { type: 'load' }
	| { type: 'loaded'; duration: number; videoContainer: HTMLElement }
	| { type: 'play' }
	| { type: 'pause' };

function formatDuration(durationInSeconds: number) {
	const hours = Math.floor(durationInSeconds / 60 / 60);
	const minutes = Math.round(durationInSeconds / 60);
	return `${hours ? `${hours}h ` : ''}${minutes}m`;
}

function videoPlayerReducer(state: VideoPlayerState, action: VideoPlayerAction): VideoPlayerState {
	switch (action.type) {
		case 'load':
			return { playerState: 'loading', playerKey: state.playerKey + 1 };
		case 'pause':
			return { ...state, playerState: 'paused' } as VideoPlayerState;
		case 'play':
			return { ...state, playerState: 'playing' } as VideoPlayerState;
		case 'loaded': {
			return {
				...state,
				duration: formatDuration(action.duration),
				videoContainer: action.videoContainer,
				playerState: 'playing',
			};
		}
		default: {
			const unsupported: never = action;
			throw new Error(`Video player action ${unsupported} is not supported`);
		}
	}
}

export function useVideoPlayerReducer() {
	return useReducer(videoPlayerReducer, { playerState: 'loading', playerKey: 0 });
}
