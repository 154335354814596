import { Icon, IconProps } from './Icon';

export const SearchIcon = (props: IconProps) => {
	return (
		<Icon viewBox="0 0 24 24" {...props}>
			<path
				d="M21.711 20.194l-5.546-5.542a7.876 7.876 0 00-1.502-11.043 7.892 7.892 0 00-11.053 1.5 7.876 7.876 0 001.502 11.044 7.892 7.892 0 009.55 0l5.547 5.541a1.076 1.076 0 001.502 0c.394-.41.377-1.073 0-1.5zM4.182 9.907a5.732 5.732 0 015.734-5.73 5.732 5.732 0 015.735 5.73 5.732 5.732 0 01-5.735 5.73 5.738 5.738 0 01-5.734-5.73z"
				fill="currentColor"
			/>
		</Icon>
	);
};
